
import axios from 'axios';

import config from '../config';

export const sinizzais = axios.create({
    baseURL: config.API_URL,
    headers: {
        'Version-Header': '2.5.6'
    }
});

export const getOperatorsInfo = () => sinizzais.get('calls/queue').then(resp => atob(JSON.parse(resp.data).queue_status));