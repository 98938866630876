const regionsData: { [id: string]: string } = {
  "54": "Новосибирск",
  "70": "Томск",
  "24": "Красноярск",
  "28": "Благовещенск",
  "75": "Чита",
  "46": "Курск",
  "154": "Бердск",
  "42": "Новокузнецк",
  "124": "Норильск",
  "125": "Прокопьевск",
};

export const regionNames = (regionNumber: string) => {
  return regionsData[regionNumber] ? regionsData[regionNumber] : "Неизвестный";
};
