import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import store from "../../../store";
import { answerCall, hangUp } from "../../../utils/atx";
import { observer } from "mobx-react";
import VolumeSlider from "../../common/volumeSlider/volumeSlider";
import MicrophoneVolumeSlider from "../../common/microphoneVolumeSlider/microphoneVolumeSlider";
import AttentionString from "../../styled/AttentionString";
import { regionNames } from "../../../utils/regions";

@observer
class IncomingDialog extends Component {
  closeIncomingDialog = () => {
    store.incomingDialogVisibility = false;
  };

  render() {
    return (
      <Dialog
        disableBackdropClick
        onClose={this.closeIncomingDialog}
        aria-labelledby="simple-dialog-title"
        open={store.incomingDialogVisibility}
      >
        <DialogTitle>
          Входящий вызов {store.isAutoCall && <span style={{ color: "red" }}>(Автодозвон)</span>}
        </DialogTitle>
        <DialogContent>
          <MicrophoneVolumeSlider iconColor="#333" />
          <VolumeSlider iconColor="#333" />
          <div>
            Номер: {store.clientNumber}
            {store.client ? (
              <div>
                <div>Имя клиента: {store.client.client_name}</div>
                {store.client.isEmployee ? <AttentionString>Сотрудник пиццерии</AttentionString> : false}
              </div>
            ) : (
              false
            )}
            {store.clientRegion ? <div>Регион: {regionNames(store.clientRegion)}</div> : false}
          </div>
          <Button color="primary" onClick={answerCall}>
            Принять
          </Button>
          <Button color="secondary" onClick={hangUp}>
            Отклонить
          </Button>
        </DialogContent>
      </Dialog>
    );
  }
}

export default IncomingDialog;
