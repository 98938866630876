import store from '../store';

export function openAlertModal(config: any) {
    store.alertModalTitle = config.title || null;
    store.alertModalContent = config.content || null;
    store.alertModalActions = config.actions || null;
    store.alertModalVisibility = true;
}

export function closeAlertModal() {
    store.alertModalVisibility = false;
}
