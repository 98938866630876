import React, {Component} from 'react';
import styled from 'styled-components';

import TextField from '@material-ui/core/TextField';
import InputMask from 'react-input-mask';
import Button from '@material-ui/core/Button';

import bg from '../../assets/img/login-bg.jpg';
import logo from '../../assets/img/logo.png';

const LoginWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    background: url(${bg});
    background-size: cover;
`;

const Login = styled.div`
    width: 100%;
    margin: auto;
    max-width: 400px;
`;

const LoginHeader = styled.div`
    color: white;
    padding: 20px;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
    background: #3f51b5;
    box-sizing: border-box;
`;

const Logo = styled.img`
    display: block;
    margin: 0 auto;
    max-height: 100px;
`;

const LoginBody = styled.div`
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background: rgb(250,250,250);
    
    & .login-input {
      width: 100%;
    }
    
    & .login-button {
      width: 100%;
      margin-top: 30px;
    }
`;

interface iProps {
    login: any,
    handleChange: any,
    username: string,
    password: string
}

class LoginPage extends Component<iProps> {
    handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') this.props.login();
    };

    render() {
        return <LoginWrapper>
            <Login>
                <LoginHeader>
                    <Logo src={logo} alt="Logo"/>
                    <div>Вход в систему</div>
                </LoginHeader>
                <LoginBody>
                    <InputMask mask="+7 (999) 999 99 99"
                       value={this.props.username}
                       onChange={this.props.handleChange('username')}>
                        {() => <TextField
                                label="Логин"
                                className="login-input"
                                type="text"
                                onKeyPress={this.handleKeyPress.bind(this)}/>
                            }
                    </InputMask>
                    <TextField
                        label="Пароль"
                        className="login-input"
                        type="password"
                        value={this.props.password}
                        onKeyPress={this.handleKeyPress.bind(this)}
                        onChange={this.props.handleChange('password')}
                    />
                    <Button variant="contained" color="primary" className="login-button"
                            onClick={this.props.login}>Войти</Button>
                </LoginBody>
            </Login>
        </LoginWrapper>
    }
}

export default LoginPage;
