import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Replay from '@material-ui/icons/Replay';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getOperatorsInfo } from '../../utils/api';

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormattedInfo = styled(Typography)`
  white-space: pre-wrap;
`;

@observer
class DashboardOperatorsInfo extends React.Component<any, any> {
  state = {
    operatorsInfo: ''
  }

  componentDidMount() {
    this.updateOperatorsInfo();
  }

  fetchInformation() {
    return getOperatorsInfo();
  }

  updateOperatorsInfo() {
    this.setState({
      operatorsInfo: ''
    });

    this.fetchInformation()
      .then(res => {
        this.setState({
          operatorsInfo: res
        });
    });
  }

  render () {
    const { operatorsInfo } = this.state;
    return (
      <div>
        <Card>
          <CardHeader
            title="Информация об операторах"
            action={
              <IconButton onClick={this.updateOperatorsInfo.bind(this)}>
                <Replay />
              </IconButton>
            }/>
          <CardContent>
            {operatorsInfo ? (
              <FormattedInfo component="pre">
                {operatorsInfo}
              </FormattedInfo>
            ) : (
              <LoaderContainer>
                <CircularProgress />
              </LoaderContainer>
            )}
          </CardContent>
        </Card>
      </div>
    )
  }
}

export default DashboardOperatorsInfo;