import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import store from '../../../store';

const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 15px;
  box-sizing: border-box;
`;

const Wrapper = styled.div`
  overflow: auto;
`;

interface iState {
    callList: any,
    page: number,
    rowsPerPage: number,
    loading: boolean
}

@observer
class CallList extends Component<{}, iState> {
    constructor(props: any) {
        super(props);

        this.state = {
            callList: null,
            page: 0,
            rowsPerPage: 10,
            loading: false
        };
    }

    handleChangePage = (event: any, page: any) => {
        this.setState({page});
    };

    handleChangeRowsPerPage = (event: any) => {
        this.setState({rowsPerPage: event.target.value});
    };

    selectClient(call: any) {
        store.enteredNumber = `${call.phone.split('#')[0]}`;
    }

    getTitle(call: any) {
        switch (call.status) {
            case 'call_made':
                return 'Исходящий';
            case 'call_received':
                return 'Входящий';
            case 'call_missed':
                return 'Пропущенный';
            case 'call_missed_outgoing':
                return 'Звонок прерван';
            default:
                return 'Не определено';
        }
    }

    render() {
        return (store.user && (
            <Paper>
                <Wrapper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Номер телефона</TableCell>
                                <TableCell align="right">Время ожидания (сек.)</TableCell>
                                <TableCell align="right">Длительность разговора (сек.)</TableCell>
                                <TableCell>Время звонка</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(store.localCallList[store.user.id].list.reverse())
                                .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((item: any, index: number) => (
                                    <TableRow
                                        style={{
                                            whiteSpace: 'nowrap',
                                            cursor: 'pointer',
                                        }}
                                        title={this.getTitle(item)}
                                        key={index}
                                        onClick={() => {
                                            this.selectClient(item)
                                        }}>
                                        <TableCell>
                                            <i style={{
                                                maxWidth: '24px',
                                                display: 'inline-block',
                                                verticalAlign: 'middle',
                                                color: item.status === 'call_made' || item.status === 'call_received' ? '#4CAF50' : '#F44336'
                                            }} className="material-icons">
                                                {item.status}
                                            </i>
                                            <span style={{
                                                display: 'inline-block',
                                                verticalAlign: 'middle',
                                            }}>{item.phone}</span>
                                        </TableCell>
                                        <TableCell align="right">
                                            {item.completewait}
                                        </TableCell>
                                        <TableCell align="right">
                                            {item.speaktime}
                                        </TableCell>
                                        <TableCell>
                                            {item.timer}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    <FooterWrapper>
                        <TablePagination
                            style={{flex: 1}}
                            component='div'
                            count={store.localCallList[store.user.id].list ? store.localCallList[store.user.id].list.length : 0}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}/>
                    </FooterWrapper>
                </Wrapper>
            </Paper>))
    }
}

export default CallList;
