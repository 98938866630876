import React, {Component, ChangeEvent, Key} from 'react';
import ReactDOM from 'react-dom';

import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {toast} from 'react-toastify';
import store from '../../store';
import {observer} from 'mobx-react';

import {call} from '../../utils/atx';
import CallList from './callList/callList';
import JustifiedString from '../styled/JustifiedString';
import { sinizzais } from "../../utils/api";

interface iProps {
    user: any
}

interface iState {
    pizzerias: any
}

@observer
class DashboardPhone extends Component<iProps, iState> {
    labelRef: HTMLElement | null;

    constructor (props: any) {
        super(props);

        this.labelRef = null;

        this.state = {
            pizzerias: []
        }
    }

    componentDidMount(){
        this.getPizzerias().then(pizzerias => {
                this.setState({pizzerias});
            });
    }

    
    getPizzerias() {
        return sinizzais({
            method: 'GET',
            url: 'pizzerias'
        }).then(response => {
            return response.data.map((pizzeria: any) => {
                if (pizzeria.phone){
                    return {
                        id: pizzeria.id,
                        address: pizzeria.name,
                        phoneNumber: pizzeria.phone,
                    }
                }
            })
            .filter((pizzeria: any) => pizzeria)
        });
    }


    makeCall = () => {
        if (store.enteredNumber) {
            const number = store.enteredNumber;
            call(number);
        }
        else {
            toast.warn('Введите номер телефона');
        }
    };

    handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        store.enteredNumber = event.target.value.replace(/(\(|\)|-|\+|_| )/g, '');
    };

    handleEnterPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        if(event.key === 'Enter') this.makeCall();
    };

    fillNumberInput = (pizzeria: any): void => {
        store.enteredNumber = pizzeria.phoneNumber;
    };

    render() {
        const user = store.user;
        const {pizzerias} = this.state;        
        
        return <div>
            {user ?
                <div>
                    {user.atxUsername && user.atxPassword ?
                        <Grid container spacing={8}>
                            <Grid item md={5} sm={12}>
                                <Grid container spacing={8}>
                                    <Grid item md={12}>
                                        <FormControl
                                            fullWidth={true}
                                            variant="outlined"
                                            >
                                            <InputLabel
                                                ref={ref => {
                                                    this.labelRef = ReactDOM.findDOMNode(ref) as HTMLElement;
                                                }}
                                                focused={true}
                                                shrink={true}
                                                htmlFor="component-disabled">Введите номер телефона</InputLabel>
                                            <OutlinedInput
                                                id="component-outlined"
                                                value={store.enteredNumber}
                                                onChange={this.handleChange.bind(this)}
                                                onKeyPress={this.handleEnterPress}
                                                labelWidth={this.labelRef ? this.labelRef.offsetWidth : 0}
                                                />
                                            <FormHelperText
                                                component="div"
                                                >
                                                <div>МТТ - через 8 (89139998585)</div>
                                                <div>МТС-Красноярск - через 0414# (0414#79139998585)</div>
                                                <div>МТС-Новосибирск - через 0107# (0107#79139998585)</div>
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={12}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.makeCall}
                                            >
                                            Позвонить
                                        </Button>
                                    </Grid>
                                </Grid>
                                <List>
                                    {pizzerias.map((pizzeria: any) => <ListItem onClick={() => this.fillNumberInput(pizzeria)} button key={pizzeria.id}>
                                        <JustifiedString>
                                            <span>{pizzeria.address}</span> <span>{pizzeria.phoneNumber}</span>
                                        </JustifiedString>
                                    </ListItem>)}
                                </List>
                            </Grid>
                            <Grid item md={7} sm={12}>
                                <CallList/>
                            </Grid>
                        </Grid> :
                        'Ваша учетная запись не привязана к учетной записи в АТС, обратитесь к администратору.'
                    } 
                </div> :
                'Загрузка'
            }
        </div>
    }
}

export default DashboardPhone;
