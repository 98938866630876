import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import store from '../../../store';
import {observer} from 'mobx-react';
import styled, {css} from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const IconWrapper = styled.div`
  margin-right: 5px;
    ${props => css`
    color: ${props.iconColor || 'white'}
  `}
`;

interface iProps {
  iconColor?: string
}

@observer
export default class VolumeSlider extends React.Component<iProps, {}> {
    onVolumeChange(value: number) {
        localStorage.setItem('volume_level', value.toString());
        store.volumeLevel = value;
    }

    render() {
        return <Wrapper title="Громкость">
            <IconWrapper iconColor={this.props.iconColor}>
                <i className="material-icons">
                    volume_up
                </i>
            </IconWrapper>
            <Slider onChange={this.onVolumeChange} value={store.volumeLevel}
                    handleStyle={{backgroundColor: '#f50a56', borderStyle: 'none'}}
                    trackStyle={{backgroundColor: '#a02a88'}} railStyle={{backgroundColor: '#263375'}}/>
        </Wrapper>
    }
}