import React, {Component} from 'react';
import Switch from '@material-ui/core/Switch';
import {observer} from 'mobx-react';

import store from '../../../store';
import {standOnLine, leaveLine} from "../../../utils/atx";

@observer
class StatusSwitcher extends Component {
    toggleStatus = () => {
        store.statusSwitcherDisabled = true;
        store.operatorStatus ? leaveLine() : standOnLine();
    };

    render() {
        return <div>
            <span>
                {store.operatorStatus ? 'Онлайн' : 'Офлайн'}
            </span>
            <Switch
                disabled={store.statusSwitcherDisabled}
                checked={store.operatorStatus}
                onChange={this.toggleStatus}
                value="checkedF"
            />
        </div>
    }
}

export default StatusSwitcher;