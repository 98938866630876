import moment from 'moment';
import axios from 'axios';
import { sinizzais } from "./api";
import store from '../store';
import {openAlertModal, closeAlertModal} from "./modal";
import {disconnect} from './atx';
import React from 'react';
import Button from '@material-ui/core/Button';

export function getAuthHeaders() {
    return JSON.parse(localStorage.getItem('auth_headers') || 'false');
};

export function isAuth(): boolean {
    const authHeaders = getAuthHeaders();

    if (!authHeaders) return false;

    if (moment(authHeaders['ExpireAt'], 'YYYY-MM-DDTHH:mm:ssZZ') < moment()) {
        return false;
    }

    axios.defaults.headers.common = {
        ...axios.defaults.headers.common,
        ...authHeaders
    };

    return true;
};

export function authenticate(credentials: any) {
    credentials.username = formatPhoneNumber(credentials.username);

    return sinizzais({
        method: 'POST',
        url: 'user/sign_in',
        data: credentials,
    }).then(response => {
            const authHeaders = {
                'ExpireAt': response.headers['expireat'],
                'Username': response.headers['username'],
                'Token': response.headers['token'],
            };

            axios.defaults.headers.common = {
                ...axios.defaults.headers.common,
                ...authHeaders
            };

            localStorage.setItem('auth_headers', JSON.stringify(authHeaders));
            store['user'] = response.data;
            return response.data;
        },
        error => {
            openAlertModal({
                title: 'Ошибка',
                content: <div>{error.response.data.message}</div>,
                actions: <div>
                    <Button onClick={closeAlertModal}>Закрыть</Button>
                </div>
            });
        });
}

export function getUser() {
    return sinizzais({
        method: 'GET',
        url: 'validate_token'
    }).then(response => {
        store.user = response.data;

        return store.user;
    });
}

export function logout() {
    return sinizzais({
        method: 'POST',
        url: 'user/sign_out'
    }).then(() => {
        store.user = null;

        axios.defaults.headers.common = {
            ...axios.defaults.headers.common,
            ExpireAt: null,
            Token: null,
            Username: null
        };

        localStorage.setItem('auth_headers', '');
        disconnect();
    })
}

function formatPhoneNumber(number: string) {
    return number.replace(/\D/g, '').slice(1);
}
