import JsSIP from "jssip";
import store from "../store";
import { Howl } from "howler";
import { toast } from "react-toastify";
import { sinizzais } from "./api";
import { reaction } from "mobx";
import moment, { Moment } from "moment";
import config from "../config";
import { openAlertModal } from "./modal";
import { get } from "lodash";

const remote_audio = document.getElementById("sip_audio_remote") as HTMLAudioElement;
const STAND_ON_LINE_NUMBER = "301";
const LEAVE_LINE_NUMBER = "302";
const PATH_HEADER_AUTO_CALL = "_request.headers['X-Calltype'][0].raw";

let phone: any;
let mediaStream: any;
let notification: any;
let currentSession: any;

let source;
let volume: any;
let audioContext: any;
let audioDestination;

let dialogStartTime: Moment;
let dialogEndTime: Moment;

const rington = new Howl({
  src: [require("../assets/audio/rington.mp3")],
  loop: true,
  volume: 1,
  preload: true,
});

const callSignal = new Howl({
  src: [require("../assets/audio/call_signal.mp3")],
  loop: false,
  volume: 1,
  preload: true,
});

const constraints = {
  audio: {
    sampleRate: 48000,
    noiseSuppression: false,
    echoCancellation: false,
    autoGainControl: false,
    googAutoGainControl: false,
    googNoiseSuppression: false,
    googHighpassFilter: false,
    googTypingNoiseDetection: false,
  },
  video: false,
};

const iceServers = [{ urls: [`stun:176.118.30.132:3478`] }];

reaction(() => store.volumeLevel, setVolumeLevel);
reaction(() => store.microphoneVolumeLevel, setMicrophoneVolumeLevel);

function setVolumeLevel(level: number) {
  const normalizeVolume = level * 0.01;
  rington.volume(normalizeVolume);
  callSignal.volume(normalizeVolume);
  remote_audio && (remote_audio.volume = normalizeVolume);
}

function setMicrophoneVolumeLevel(level: number) {
  const normalizeVolume = level * 0.01;
  volume.gain.setValueAtTime(normalizeVolume, audioContext.currentTime);
}

export function connect() {
  // JsSIP.debug.enable('JsSIP:*');
  JsSIP.debug.disable();

  const socket = new JsSIP.WebSocketInterface(config.ATX_URL);
  const configuration = {
    sockets: [socket],
    uri: `sip:${store.user.atxUsername}@${config.ATX_BASE_URL}`,
    password: store.user.atxPassword,
    session_timers: false,
    register_expires: 60 * 60 * 24 * 30 * 12, // год
  };

  phone = new JsSIP.UA(configuration);

  phone.on("newRTCSession", (e: any) => {
    onNewSession(e);
  });

  navigator.mediaDevices
    .getUserMedia({
      audio: true,
    })
    .then(
      (stream) => {
        audioContext = new AudioContext();
        source = audioContext.createMediaStreamSource(stream);
        audioDestination = audioContext.createMediaStreamDestination();
        volume = audioContext.createGain();

        source.connect(volume);
        volume.connect(audioDestination);
        mediaStream = audioDestination.stream;

        setVolumeLevel(store.volumeLevel);
        setMicrophoneVolumeLevel(store.microphoneVolumeLevel);

        phone.start();
      },
      (err) => {
        openAlertModal({
          content: "Не найден микрофон",
        });
      }
    );

  phone.on("connected", () => (store.operatorStatus ? standOnLine() : leaveLine()));
}

export function call(phoneNumber: string, isTechCall: boolean = false) {
  phone.call(phoneNumber, {
    mediaConstraints: constraints,
    sessionTimersExpires: 30 * 60,
    mediaStream: mediaStream,
    pcConfig: {
      iceServers: iceServers,
    },
  });

  if (isTechCall) return false;

  store.callDialogVisibility = true;
}

function createNotification() {
  notification = new Notification("Входящий вызов");
}

function onNewSession(e: any) {
  let clientNumber: string;
  let startWaitingTime = moment();
  let endWaitingTime;
  let startCallTime = moment();

  currentSession = e.session;
  store.currentSession = currentSession;

  currentSession.direction === "incoming"
    ? (clientNumber = e.request.from.uri.user)
    : (clientNumber = e.request.ruri.user);

  /*
   * Для того, чтобы оператору понимать с какого региона звонит клиент, АТС добавляет номер региона через решетку.
   * Если регион NSK, АТС ничего не добавляет
   * */
  store.clientRegion = clientNumber.split("#")[1] ? clientNumber.split("#")[1] : "54";
  store.clientNumber = clientNumber = clientNumber.split("#")[0];

  if (currentSession.direction === "incoming") {
    const isAutoCall = get(currentSession, PATH_HEADER_AUTO_CALL, "");

    if (isAutoCall === "AutoCall") {
      store.isAutoCall = true;
    }
    rington.play();
    store.incomingDialogVisibility = true;

    if (!Notification) {
      console.warn("Нотификации не поддерживаются браузером");
    } else if (Notification.permission === "granted") {
      createNotification();
    } else {
      Notification.requestPermission((permission) => {
        if (permission === "granted") {
          createNotification();
        }
      });
    }
  }

  currentSession.on("failed", (event: any) => {
    endWaitingTime = moment();
    store.localCallList[store.user.id].list.push({
      phone: clientNumber,
      status: currentSession.direction === "incoming" ? "call_missed" : "call_missed_outgoing",
      completewait:
        new Date(+endWaitingTime - +startWaitingTime).getSeconds() +
        60 * new Date(+endWaitingTime - +startWaitingTime).getMinutes(),
      timer: startCallTime.format("YYYY-MM-DD HH:mm:ss"),
      speaktime: 0,
    });
    rington.stop();
    store.enteredNumber = "";

    if (event.originator === "remote" && (event.cause === "Canceled" || event.cause === "Busy")) {
      toast.info("Клиент сбросил звонок");
    }

    store.incomingDialogVisibility = false;
    store.callDialogVisibility = false;
    store.isAutoCall = false;

    if (notification) {
      notification.close();
    }
  });

  currentSession.on("connecting", () => {
    currentSession.connection.ontrack = (event: any) => {
      dialogStartTime = moment();
      callSignal.play();
      store.callTimerVisibility = true;
      remote_audio.srcObject = event.streams[0];
      remote_audio.play();

      if (notification) {
        notification.close();
      }
    };
  });

  currentSession.on("ended", () => {
    endWaitingTime = moment();
    dialogEndTime = moment();

    saveCallToCallList(clientNumber, startWaitingTime, startCallTime, endWaitingTime);

    store.enteredNumber = "";

    if (isServiceCall(clientNumber)) {
      store.statusSwitcherDisabled = false;
      updateOperatorStatus(clientNumber);
    }

    store.callDialogVisibility = false;
    store.callTimerVisibility = false;
    store.isAutoCall = false;
    callSignal.play();

    if (notification) {
      notification.close();
    }
  });

  const formattedNumber = formatNumber(clientNumber);

  if (!validateNumber(+formattedNumber)) return false;

  fetchClient(formattedNumber);
}

function isServiceCall(number: string) {
  return number === LEAVE_LINE_NUMBER || number.split("*")[0] === STAND_ON_LINE_NUMBER;
}

function updateOperatorStatus(number: string) {
  if (number === LEAVE_LINE_NUMBER) {
    store.operatorStatus = false;
  } else if (number.split("*")[0] === STAND_ON_LINE_NUMBER) {
    store.operatorStatus = true;
  }
}

function saveCallToCallList(
  clientNumber: string,
  startWaitingTime: Moment,
  startCallTime: Moment,
  endWaitingTime: Moment
) {
  store.localCallList[store.user.id].list.push({
    phone: currentSession.direction === "incoming" ? `8${clientNumber}` : clientNumber,
    status: currentSession.direction === "incoming" ? "call_received" : "call_made",
    completewait: getSecondsDiff(startWaitingTime, endWaitingTime),
    timer: startCallTime.format("YYYY-MM-DD HH:mm:ss"),
    speaktime: getSecondsDiff(dialogStartTime, dialogEndTime),
  });
}

function getSecondsDiff(startDate: Moment, endDate: Moment) {
  return new Date(+endDate - +startDate).getSeconds() + 60 * new Date(+endDate - +startDate).getMinutes();
}

function fetchClient(formattedNumber: string) {
  const clients = sinizzais({
    method: "GET",
    url: "clients",
    params: {
      phone: formattedNumber,
    },
  });

  const employees = sinizzais({
    method: "GET",
    url: "users",
    params: {
      keyword: formattedNumber,
    },
  });

  return Promise.all([clients, employees]).then((responses) => {
    const client = responses[0].data;
    const employees = responses[1].data;
    if (client) {
      client.isEmployee = Array.isArray(employees) && employees.length && employees[0].enabled;
    }

    store.client = client;
  });
}

function validateNumber(number: number) {
  return number.toString().length >= 10;
}

function formatNumber(number: string) {
  return number.substr(number.length - 10);
}

export function answerCall() {
  rington.stop();
  store.incomingDialogVisibility = false;
  store.callDialogVisibility = true;
  dialogStartTime = moment();

  currentSession.answer({
    mediaConstraints: constraints,
    mediaStream: mediaStream,
    sessionTimersExpires: 30 * 60,
    pcConfig: {
      iceServers: iceServers,
    },
  });

  currentSession.connection.ontrack = (event: any) => {
    callSignal.play();
    store.callTimerVisibility = true;
    remote_audio.srcObject = event.streams[0];
    remote_audio.play();

    if (notification) {
      notification.close();
    }
  };
}

export function hangUp() {
  rington.stop();
  store.incomingDialogVisibility = false;
  store.callDialogVisibility = false;

  currentSession.terminate();
}

export function disconnect() {
  phone.unregister();
}

export function standOnLine() {
  call(`sip:${STAND_ON_LINE_NUMBER}${store.user.callPriority ? "*" + store.user.callPriority : ""}`, true);
}

export function leaveLine() {
  call(`sip:${LEAVE_LINE_NUMBER}`, true);
}
