import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import store from "../../../store";
import { observer } from "mobx-react";
import { hangUp } from "../../../utils/atx";
import config from "../../../config";
import VolumeSlider from "../../common/volumeSlider/volumeSlider";
import Timer from "../../common/timerComponent/timerComponent";
import MicrophoneVolumeSlider from "../../common/microphoneVolumeSlider/microphoneVolumeSlider";
import AttentionString from "../../styled/AttentionString";
import { regionNames } from "../../../utils/regions";

@observer
class IncomingDialog extends Component {
  closeCallDialog = () => {
    store.callDialogVisibility = false;
  };

  goToOrderList = () => {
    window.open(`${config.PM_URL}?client-phone=${store.clientNumber}&action=orderList`, "_blank");
  };

  goToCreateOrder = () => {
    window.open(`${config.PM_URL}?client-phone=${store.clientNumber}&action=createOrder`, "_blank");
  };

  render() {
    return (
      <Dialog
        onClose={this.closeCallDialog}
        disableBackdropClick
        aria-labelledby="simple-dialog-title"
        open={store.callDialogVisibility}
      >
        <DialogTitle>Текущий вызов</DialogTitle>
        <DialogContent>
          <MicrophoneVolumeSlider iconColor="#333" />
          <VolumeSlider iconColor="#333" />
          <div>
            Номер: {store.clientNumber}
            {store.client && store.client.client_name ? (
              <div>
                <div>Имя: {store.client.client_name}</div>
                {store.client.isEmployee ? <AttentionString>Сотрудник пиццерии</AttentionString> : false}
              </div>
            ) : (
              ""
            )}
            {store.clientRegion ? <div>Регион: {regionNames(store.clientRegion)}</div> : false}
            {store.callTimerVisibility ? (
              <div>
                Время звонка: <Timer />
              </div>
            ) : (
              false
            )}
          </div>
          <Button color="primary" onClick={this.goToOrderList}>
            Список заказов
          </Button>
          <Button color="primary" onClick={this.goToCreateOrder}>
            Создать заказ
          </Button>
          <Button color="secondary" onClick={hangUp}>
            Отклонить
          </Button>
        </DialogContent>
      </Dialog>
    );
  }
}

export default IncomingDialog;
