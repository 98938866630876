import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import store from '../../../store';
import {observer} from 'mobx-react';

@observer
export class AlertModal extends React.Component {
    render() {
        return <Dialog
            open={store.alertModalVisibility}
            onClose={() => {
                store.alertModalTitle = null;
                store.alertModalContent = null;
                store.alertModalActions = null;
            }}
            aria-labelledby="alert modal">
            <DialogTitle>{store.alertModalTitle || 'Ошибка!'}</DialogTitle>
            <DialogContent>
                {store.alertModalContent || 'Что-то пошло не так...'}
            </DialogContent>
            {store.alertModalActions ? <DialogActions>
                {store.alertModalActions}
            </DialogActions> : false}
        </Dialog>
    }
}
