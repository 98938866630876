import React from 'react';
import moment from 'moment';

interface iState {
    seconds: number
}

export default class Timer extends React.Component<{}, iState> {
    interval: any = null

    constructor(props: any) {
        super(props);

        this.state = {
            seconds: 0
        }
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            this.setState({
                seconds: this.state.seconds + 1
            })
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render () {
        return <span>{moment().hour(0).minute(0).second(this.state.seconds).format('mm:ss')}</span>
    }
}